import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./Hero.css";

function Hero() {
    return (
        <>
            <div className="hero-container">
                <h1>
                    Unique and Custom <br />
                    Pool Designs
                </h1>
                <p>Creating Your Ideas In 3D</p>
                <div className="hero-btns">
                    <Button
                        className="btns"
                        buttonStyle="btn--outline"
                        buttonSize="btn--large"
                        buttonLink="/contact"
                    >
                        INQUIRE
                    </Button>
                </div>
            </div>
        </>
    );
}

export default Hero;
