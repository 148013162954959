import image1 from "../images/3D/1015_Oak_Briar_Lane_2-Dr_Horton-NSP_004.jpg";
import image2 from "../images/3D/1015_Oak_Briar_Lane_2-Dr_Horton-NSP_008.jpg";
import image3 from "../images/3D/1015_Oak_Briar_Lane_2-Dr_Horton-NSP_011.jpg";

import image4 from "../images/3D/Ceulemans_Stefan_new_home-NSP_001.jpg";
import image5 from "../images/3D/Ceulemans_Stefan_new_home-NSP_003.jpg";
import image6 from "../images/3D/Ceulemans_Stefan_new_home-NSP_007.jpg";

import image7 from "../images/3D/Chumbley-NSP_new_design_005.jpg";
import image8 from "../images/3D/Chumbley-NSP_rec_2_009.jpg";
import image9 from "../images/3D/Chumbley-NSP_rec_2_016.jpg";
import image10 from "../images/3D/Chumbley-NSP_rec_2_021.jpg";

import image11 from "../images/3D/MICO_pool_samples(6).jpg";

import image13 from "../images/pool_vertical_1.jpg";
import image14 from "../images/pool_vertical_2.jpg";
import image15 from "../images/pool_vertical_3.jpg";

import image16 from "../images/3D/reggie/Reggie-IBP2_002.jpg";
import image17 from "../images/3D/reggie/Reggie-IBP2_004.jpg";
import image18 from "../images/3D/reggie/Reggie-IBP2_005.jpg";
import image19 from "../images/3D/reggie/Reggie-IBP2_007.jpg";
import image20 from "../images/3D/reggie/Reggie-IBP2_014.jpg";

export const GalleryImages = [
    {
        id: 1,
        imageSrc: image1,
    },
    {
        id: 2,
        imageSrc: image2,
    },
    {
        id: 3,
        imageSrc: image3,
    },
    {
        id: 4,
        imageSrc: image4,
    },
    {
        id: 5,
        imageSrc: image5,
    },
    {
        id: 6,
        imageSrc: image6,
    },
    {
        id: 7,
        imageSrc: image7,
    },
    {
        id: 8,
        imageSrc: image8,
    },
    {
        id: 9,
        imageSrc: image9,
    },
    {
        id: 10,
        imageSrc: image10,
    },
    {
        id: 11,
        imageSrc: image11,
    },
    // {
    //     id: 12,
    //     imageSrc: image12,
    // },
    {
        id: 13,
        imageSrc: image13,
    },
    {
        id: 14,
        imageSrc: image14,
    },
    {
        id: 15,
        imageSrc: image15,
    },
    {
        id: 16,
        imageSrc: image16,
    },
    {
        id: 17,
        imageSrc: image17,
    },
    {
        id: 18,
        imageSrc: image18,
    },
    {
        id: 19,
        imageSrc: image19,
    },
    {
        id: 20,
        imageSrc: image20,
    },
];
