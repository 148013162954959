import image1 from "../images/3D/group2/Grego_Pools_001.jpg";
import image2 from "../images/3D/group2/Grego_Pools_002.jpg";
import image3 from "../images/3D/group2/Grego_Pools_003.jpg";
import image4 from "../images/3D/group2/Grego_Pools_004.jpg";
import image5 from "../images/3D/group2/Grego_Pools_005.jpg";
import image6 from "../images/3D/group2/Grego_Pools_006.jpg";
import image7 from "../images/3D/group2/Grego_Pools_007.jpg";
import image8 from "../images/3D/group2/Grego_Pools_008.jpg";
import image9 from "../images/3D/group2/Grego_Pools_009.jpg";
import image10 from "../images/3D/group2/Grego_Pools_010.jpg";
import image11 from "../images/3D/group2/Grego_Pools_011.jpg";
import image12 from "../images/3D/group2/Grego_Pools_012.jpg";
import image13 from "../images/3D/group2/Grego_Pools_013.jpg";
//import image14 from "../images/3D/group2/Grego_Pools_014.jpg";
import image15 from "../images/3D/group2/Grego_Pools_015.jpg";
import image16 from "../images/3D/group2/Grego_Pools_016.jpg";
import image17 from "../images/3D/group2/Grego_Pools_017.jpg";
import image18 from "../images/3D/group2/Grego_Pools_018.jpg";
import image19 from "../images/3D/group2/Grego_Pools_019.jpg";
import image20 from "../images/3D/group2/Grego_Pools_020.jpg";

export const SliderImages = [
    {
        url: `${image1}`,
        title: "pool1",
    },
    {
        url: `${image2}`,
        title: "pool2",
    },
    {
        url: `${image3}`,
        title: "pool3",
    },
    {
        url: `${image4}`,
        title: "pool4",
    },
    {
        url: `${image5}`,
        title: "pool5",
    },
    {
        url: `${image6}`,
        title: "pool6",
    },
    {
        url: `${image7}`,
        title: "pool7",
    },
    {
        url: `${image8}`,
        title: "pool8",
    },
    {
        url: `${image9}`,
        title: "pool9",
    },
    {
        url: `${image10}`,
        title: "pool10",
    },
    {
        url: `${image11}`,
        title: "pool11",
    },
    {
        url: `${image12}`,
        title: "pool12",
    },
    {
        url: `${image13}`,
        title: "pool13",
    },
    // {
    //     url: `${image14}`,
    //     title: "pool14",
    // },
    {
        url: `${image15}`,
        title: "pool15",
    },
    {
        url: `${image16}`,
        title: "pool1",
    },
    {
        url: `${image17}`,
        title: "pool17",
    },
    {
        url: `${image18}`,
        title: "pool18",
    },
    {
        url: `${image19}`,
        title: "pool19",
    },
    {
        url: `${image20}`,
        title: "pool20",
    },
];
