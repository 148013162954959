import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Home from "./Components/Pages/Home";
import GalleryPage from "./Components/Pages/GalleryPage";
import Designs from "./Components/Pages/Designs";
import Stucco from "./Components/Pages/Stucco";
import Contact from "./Components/Pages/Contact";

function App() {
    return (
        <>
            <Router>
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/gallery" element={<GalleryPage />} />
                    <Route path="/designs" element={<Designs />} />
                    <Route path="/stucco" element={<Stucco />} />
                    <Route path="/contact" element={<Contact />} />
                </Routes>
                <Footer />
            </Router>
        </>
    );
}

export default App;
