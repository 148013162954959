import React from "react";
import "./Contact.css";

function Contact() {
    return (
        <>
            <div className="contact-page">
                <div className="contact-form-container">
                    <form
                        name="contact v1"
                        method="POST"
                        data-netlify="true"
                        onSubmit="submit"
                        data-netlify-honeypot="bot-field"
                    >
                        <input
                            type="hidden"
                            name="form-name"
                            value="contact v1"
                        />
                        <div hidden>
                            <input name="bot-field" />
                        </div>
                        <p>
                            <label>
                                Your Name: <input type="text" name="name" />
                            </label>
                        </p>
                        <p>
                            <label>
                                Your Email: <input type="email" name="email" />
                            </label>
                        </p>
                        <p>
                            <label>
                                Message: <textarea name="message"></textarea>
                            </label>
                        </p>
                        <p>
                            <button type="submit">Send</button>
                        </p>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Contact;
