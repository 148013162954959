import React from "react";
import "./Stucco.css";
import { useInView } from "react-intersection-observer";

import image1 from "../../images/stucco3.jpg";
import image2 from "../../images/stucco5.jpg";

export default function Stucco() {
    const { ref: myRef1, inView: myElementIsVisible1 } = useInView();
    const { ref: myRef2, inView: myElementIsVisible2 } = useInView();
    return (
        <>
            <div className="stucco">
                <h1 className="stucco-header">STUCCO</h1>
            </div>
            <div className="stucco-content">
                <div
                    className={
                        myElementIsVisible1
                            ? "stucco-left-1 stucco-left-move"
                            : "stucco-left-1"
                    }
                    ref={myRef1}
                >
                    <h1>Give Your House a Make Over</h1>
                    <p>
                        MICO not only gives your backyard a fresh new look, but
                        also your house! Adding stucco to your home is a great
                        way of giving your home a new facelift, enhancing the
                        value and beauty of your home.
                    </p>
                </div>
                <div className="stucco-right-pic-1">
                    <img
                        className="image-stucco-right"
                        src={image1}
                        alt="Stucco House"
                    ></img>
                </div>
            </div>
            <div className="stucco-content">
                <div className="stucco-left-pic-1">
                    <img
                        className="image-stucco-left"
                        src={image2}
                        alt="Stucco Textures"
                    ></img>
                </div>
                <div
                    className={
                        myElementIsVisible2
                            ? "stucco-right-1 stucco-right-move"
                            : "stucco-right-1"
                    }
                    ref={myRef2}
                >
                    <h1>Our Team Ensures 100% Satisfaction</h1>
                    <p>
                        Whether you are redesigning your home or building a new
                        one, you can trust our team to give you the dream home
                        you have always desired.
                    </p>
                </div>
            </div>
        </>
    );
}
