import React, { useState } from "react";
import "./Gallery.css";
import { GalleryImages } from "./GalleryImages";

function Gallery() {
    let data = GalleryImages;

    const [modal, setModal] = useState(false);
    const [tempimgSrc, setTempimgSrc] = useState("");

    const getImage = (imageSrc) => {
        setTempimgSrc(imageSrc);
        setModal(true);
    };

    return (
        <>
            <div className={modal ? "modal open" : "modal"}>
                <img src={tempimgSrc} onClick={() => setModal(false)} />
                <i className="fas fa-times" onClick={() => setModal(false)} />
            </div>
            <div className="gallery">
                {data.map((item, index) => {
                    return (
                        <div className="pics" key={index}>
                            <img
                                src={item.imageSrc}
                                style={{ width: "100%" }}
                                onClick={() => getImage(item.imageSrc)}
                                alt={`pool ${index + 1}`}
                            />
                        </div>
                    );
                })}
            </div>
            ;
        </>
    );
}

export default Gallery;
