import React from "react";
import { Link } from "react-router-dom";
import "./Cards.css";
import { useInView } from "react-intersection-observer";

function Cards() {
    const top = () => {
        window.scrollTo(0, 0);
    };

    const { ref: myRef1, inView: myElementIsVisible1 } = useInView();

    // className={
    //     myElementIsVisible1
    //         ? "designs-left-1 designs-left-move"
    //         : "designs-left-1"
    // }
    // ref={myRef1}

    return (
        <div
            class={myElementIsVisible1 ? "container card-move" : "container"}
            ref={myRef1}
        >
            <Link to="/designs" onClick={top}>
                <div class="card card0">
                    <div class="border">
                        <h2 className="card0-h2">Designs</h2>
                    </div>
                </div>
            </Link>
            <Link to="/stucco" onClick={top}>
                <div class="card card1">
                    <div class="border">
                        <h2>Stucco</h2>
                    </div>
                </div>
            </Link>
            <Link to="/gallery" onClick={top}>
                <div class="card card2">
                    <div class="border">
                        <h2>Gallery</h2>
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default Cards;
