import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import logo from "../images/logo5.png";

const top = () => {
    window.scrollTo(0, 0);
};

function Footer() {
    return (
        <>
            <div className="footer-container">
                <div className="footer-links">
                    <div className="footer-link-wrapper">
                        <div className="footer-link-items">
                            <h2>About</h2>
                            <p className="footer-about">
                                Our goal at Mico Pool Designs and Consulting is
                                to make your dream backyard a reality. Whether
                                your vision includes a fireplace, kitchen, or
                                spa, we can make sure your new backyard has all
                                of the features you could ask for. Our designs
                                speak for themselves.
                            </p>
                        </div>
                        <div className="footer-link-items">
                            <h2>Contact</h2>
                            <p className="contact">
                                832-794-9995
                                <br />
                                michael.pools@yahoo.com
                                <br />
                                Houston, TX
                            </p>
                        </div>
                        <div className="footer-link-items">
                            <h2>Explore</h2>
                            <Link to="/Designs" onClick={top}>
                                Designs
                            </Link>
                            <Link to="/gallery" onClick={top}>
                                Gallery
                            </Link>
                            <Link to="/stucco" onClick={top}>
                                Stucco
                            </Link>
                            <Link to="/contact" onClick={top}>
                                Contact
                            </Link>
                        </div>
                    </div>
                </div>
                <section className="social-media">
                    <div className="social-media-wrapper">
                        <div className="footer-logo">
                            <Link to="/" className="social-logo">
                                <img
                                    src={logo}
                                    style={{ height: "100px" }}
                                    onClick={top}
                                    alt="logo"
                                />
                            </Link>
                        </div>
                        <small className="website-rights">
                            Mico Design and Consulting © 2022
                        </small>
                        {/* <div className="social-icons" hidden>
                            <Link
                                className="social-icon-link instagram"
                                to="/"
                                target="_blank"
                                aria-label="Instagram"
                            >
                                <i className="fab fa-instagram"></i>
                            </Link>
                            <Link
                                className="social-icon-link facebook"
                                to="/"
                                target="_blank"
                                aria-label="Facebook"
                            >
                                <i className="fab fa-facebook"></i>
                            </Link>
                        </div> */}
                    </div>
                </section>
            </div>
        </>
    );
}

export default Footer;
