import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "../images/logo5.png";

function Navbar() {
    const top = () => {
        window.scrollTo(0, 0);
    };

    //change nav color while scrolling
    const [color, setColor] = useState(false);
    const changeColor = () => {
        if (window.scrollY >= 90) {
            setColor(true);
        } else {
            setColor(false);
        }
    };

    window.addEventListener("scroll", changeColor);

    // setting mobile nav
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    // close menu on click
    const closeMobileMenu = () => setClick(false);

    const onClickEvent = () => {
        top();
        closeMobileMenu();
    };

    return (
        <>
            <nav className={color ? "navbar navbar-bg" : "navbar"}>
                <div className="navbar-container">
                    <Link
                        to="/"
                        className="navbar-logo"
                        onClick={(closeMobileMenu, top)}
                    >
                        <img
                            className="logo"
                            src={logo}
                            alt="Mico Design and Consulting logo"
                            path="/"
                        />
                    </Link>
                    <div className="menu-icon" onClick={handleClick}>
                        <i className={click ? "fas fa-times" : "fas fa-bars"} />
                    </div>
                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                        <li className="nav-item">
                            <Link
                                to="/"
                                className="nav-links"
                                onClick={onClickEvent}
                            >
                                Home
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/designs"
                                className="nav-links"
                                onClick={onClickEvent}
                            >
                                Designs
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/gallery"
                                className="nav-links"
                                onClick={onClickEvent}
                            >
                                Gallery
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/stucco"
                                className="nav-links"
                                onClick={onClickEvent}
                            >
                                Stucco
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/contact"
                                className="nav-links"
                                onClick={onClickEvent}
                            >
                                Contact
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
