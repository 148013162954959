import React, { useState } from "react";
import "../../App.css";
import Hero from "../Hero";
import "./Home.css";
import Cards from "../Cards";
import pool from "../../images/pool4.jpg";
import { Button } from "../Button";

function Home() {
    const [fade, setFade] = useState(false);
    const fadeIn = () => {
        if (window.scrollY >= 90) {
            setFade(true);
        } else {
            setFade(false);
        }
    };
    window.addEventListener("scroll", fadeIn);

    return (
        <>
            <div className="home">
                <Hero />
                <div className="home-about">
                    <div className="home-left">
                        <h1>Welcome to MICO Pool Designs and Consulting</h1>
                        <p>
                            Our goal at Mico Pool Designs and Consulting is to
                            make your dream backyard a reality. Whether your
                            vision includes a fireplace, kitchen, or spa, we can
                            make sure your new backyard has all of the features
                            you could ask for. Our designs speak for themselves.
                        </p>
                    </div>
                    <div
                        className={
                            fade
                                ? "home-right-pic"
                                : "home-right-pic home-right-pic-fade"
                        }
                    >
                        <img className="image-home" src={pool} alt="Pool"></img>
                    </div>
                </div>
                <div className="home-mid">
                    <div className="home-mid-content">
                        <h1>Designs in 3D</h1>
                        <div className="home-mid-btn">
                            <Button
                                className="btns"
                                buttonStyle="btn--outline"
                                buttonSize="btn--large"
                                buttonLink="/gallery"
                            >
                                GALLERY
                            </Button>
                        </div>
                    </div>
                </div>
                <Cards />
            </div>
        </>
    );
}

export default Home;
