import React from "react";
import "./Designs.css";
import { useInView } from "react-intersection-observer";

import image1 from "../../images/notes.jpg";
import image2 from "../../images/brainstorm.jpg";
import image3 from "../../images/3D/group2/Grego_Pools_015.jpg";
import image4 from "../../images/3D/reggie/Reggie-IBP2_004.jpg";

export default function Designs() {
    const { ref: myRef1, inView: myElementIsVisible1 } = useInView();
    const { ref: myRef2, inView: myElementIsVisible2 } = useInView();
    const { ref: myRef3, inView: myElementIsVisible3 } = useInView();
    const { ref: myRef4, inView: myElementIsVisible4 } = useInView();
    return (
        <>
            <div className="designs">
                <h1 className="designs-header">DESIGNS</h1>
            </div>
            <div className="designs-wrapper">
                <div className="designs-content">
                    <div
                        className={
                            myElementIsVisible1
                                ? "designs-left-1 designs-left-move"
                                : "designs-left-1"
                        }
                        ref={myRef1}
                    >
                        <h1>Collecting Your Requirements</h1>
                        <p>
                            The first step to any design is engaging with you to
                            see how we can apply all of your requirements to
                            your new design. Whether it be a fireplace, spa, or
                            kitchen, we can identify exactly what you want your
                            new personal paradise to look like.
                        </p>
                    </div>
                    <div className="designs-right-pic-1">
                        <img
                            className="image-designs-right"
                            src={image1}
                            alt="Pool"
                        ></img>
                    </div>
                </div>
                <div className="designs-content">
                    <div className="designs-left-pic-1">
                        <img
                            className="image-designs-left"
                            src={image2}
                            alt="Pool"
                        ></img>
                    </div>
                    <div
                        className={
                            myElementIsVisible2
                                ? "designs-right-1 designs-right-move"
                                : "designs-right-1"
                        }
                        ref={myRef2}
                    >
                        <h1>Brainstorming With You</h1>
                        <p>
                            We not only love designing pools, but we also love
                            to innovate. Let us brainstorm and create new ideas
                            with you to make sure your backyard is truly one of
                            a kind.
                        </p>
                    </div>
                </div>
                <div className="designs-content shift-up">
                    <div
                        className={
                            myElementIsVisible3
                                ? "designs-left-1 designs-left-move"
                                : "designs-left-1"
                        }
                        ref={myRef3}
                    >
                        <h1>Building a Protoype</h1>
                        <p>
                            Now that we know what we want to create, lets start
                            developing the 3D rendering of your dream backyard.
                            Remember, this is just a rough draft, so any new
                            ideas you may have can easily be applied.
                        </p>
                    </div>
                    <div className="designs-right-pic-1">
                        <img
                            className="image-designs-right"
                            src={image3}
                            alt="Pool"
                        ></img>
                    </div>
                </div>
                <div className="designs-content">
                    <div className="designs-left-pic-1">
                        <img
                            className="image-designs-left"
                            src={image4}
                            alt="Pool"
                        ></img>
                    </div>
                    <div
                        className={
                            myElementIsVisible4
                                ? "designs-right-1 designs-right-move"
                                : "designs-right-1"
                        }
                        ref={myRef4}
                    >
                        <h1>The Finished Product</h1>
                        <p>
                            Congratulations! You now have the final draft of
                            your new 3D designed paradise! Now it is time to
                            make the 3D rendering a reality.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}
