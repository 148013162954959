import React from "react";
import "./GalleryPage.css";
import Gallery from "../Gallery";
import ImageSlider from "../ImageSlider";
import { SliderImages } from "../SliderImages";

export default function GalleryPage() {
    const slides = SliderImages;

    const containerStyles = {
        width: "100%",
        maxWidth: "1440px",
        height: "600px",
        margin: "0 auto 50px",
    };

    return (
        <>
            <div className="gallery-page">
                <h1 className="gallery-page-header">GALLERY</h1>
            </div>
            <div>
                <h1 className="gallery-head">FEATURED MODEL</h1>
            </div>
            <div style={containerStyles}>
                <ImageSlider slides={slides} />
            </div>
            <Gallery />
        </>
    );
}
